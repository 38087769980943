export enum ActionType {
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  LOGOUT = "LOGOUT",
  SET_LOADING_FLAG = "SET_LOADING_FLAG",
  SELECT_COURSE = "SELECT_COURSE",
  SET_TOP_MENU = "SET_TOP_MENU",
  SET_ACTIVE_RESOURCE = "SET_ACTIVE_RESOURCE",
}

export type Action =
  | {
      type: ActionType.GET_USER_SUCCESS;
      payload: any;
    }
  | {
      type: ActionType.LOGOUT;
      payload: any;
    }
  | {
      type: ActionType.SELECT_COURSE;
      payload: any;
    }
  | {
      type: ActionType.SET_LOADING_FLAG;
      payload: any;
    }
  | {
      type: ActionType.SET_TOP_MENU;
      payload: any;
    }
  | {
      type: ActionType.SET_ACTIVE_RESOURCE;
      payload: any;
    };

export const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case ActionType.SET_LOADING_FLAG:
      return {
        ...state,
        page_loading: action.payload,
      };
    case ActionType.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        page_loading: false,
      };
    case ActionType.SELECT_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
        page_loading: false,
      };
    case ActionType.LOGOUT:
      localStorage.removeItem("token");
      // localStorage.removeItem("user");
      return {
        ...state,
        user: null,
      };
    case ActionType.SET_TOP_MENU:
      return {
        ...state,
        activeMenu: action.payload,
      };
    case ActionType.SET_ACTIVE_RESOURCE:
      return {
        ...state,
        activeResource: action.payload,
      };

    default:
      return state;
  }
};
