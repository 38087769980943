import React, { FC, useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import UserDropdown from "./UserDropdown";
import ButtonDropdown from "./ButtonDropdown";
import { HiMenu } from "react-icons/hi";
import NOTIFICATION_ICON from "../../svg/notification.svg";
import moment from "moment";
import { AppContext } from "../../App";
import LipiEduAPI from "@lipitech/edu-client-sdk";

interface IAppBarUser {
  getCurrentUser: any;
  onCourseSelect: any;
  imageBaseUrl: string;
  notifications: any;
  markReadNotifications: any;
  viewAllNotifications: any;
  viewNotificationDetails: any;
  activeItem: any;
  setActiveItem: any;
  toggleDrawer: any;
}

const eventKey = {
  HOME: "home",
  BATCH: "batch",
  DOUBT: "doubt",
  TEST: "test",
  LIVE: "live",
};

const AppBarUser: FC<any> = ({
  getCurrentUser,
  onCourseSelect,
  imageBaseUrl,
  notifications,
  markReadNotifications,
  viewAllNotifications,
  viewNotificationDetails,
  toggleDrawer,
}: IAppBarUser) => {
  const { state, dispatch } = useContext<any>(AppContext);
  const [studentProfile, setStudentProfile] = useState<any>();
  const [notificationList, setNotificationList] = useState<any>({
    item: [],
    total: 0,
  });

  const [isOpen, setIsOpen] = useState<any>(false);
  const location = useLocation();


  useEffect(() => {
    getCurrentUser()
      .then(({ data: { data } }: any) => {
        setStudentProfile(data);
        if (!(data.selectedCourses && data.selectedCourses.length)) {
          onCourseSelect(data.selectedCourses, true);
        }
      })
      .catch(() => { });

    getNotifications();
  }, []);

  const getNotifications = () => {
    notifications({ poplate: true })
      .then(({ data }: any) => {
        setNotificationList(data.data);
      })
      .catch(() => { });
  };

  const readNotifications = (notification: any) => {
    if (notification.isRead) {
      setIsOpen(false);
      viewNotificationDetails(notification);
    } else {
      markReadNotifications(notification._id)
        .then(() => {
          setIsOpen(false);
          viewNotificationDetails(notification);
        })
        .catch(() => { });
    }
  };

  const { logo } = LipiEduAPI.getConfig();

  return (
    <>
      {!location.pathname.includes("/course/batch/") ? (
        <nav className="student-nav">
          <div className="logo">
            <HiMenu onClick={toggleDrawer} />
            <Link to="/course/wall">
              <img
                src={LipiEduAPI.asset.getAssetUrl(logo?.key)}
                alt="logo"
                width="120px"
              />
            </Link>
          </div>

          <div className="left">
            <div className="logo">
              <a href="#home">
                <img src={`${imageBaseUrl}logo.svg`} alt="logo" width="150px" />
              </a>
            </div>
          </div>
          <div className="right">
            <div className="student-nav_wrap">
              <div className="notification-wrapper">
                <div
                  className="notification-toggle"
                  onClick={() => {
                    if (!isOpen) {
                      getNotifications();
                    }
                    setIsOpen(!isOpen);
                  }}
                >
                  <img
                    src={NOTIFICATION_ICON}
                    alt={"notification"}
                    className={"noti-icon"}
                  />
                </div>
                <div className={isOpen ? "menu-wrap active" : "menu-wrap "}>
                  <h3>Notifications</h3>
                  {/* {notificationList.notifications.length ?  */}
                  {notificationList?.total ? (
                    <ul>
                      {notificationList?.list.map((item: any) => {
                        return (
                          <li
                            key={item._id}
                            className={item.isRead ? "" : "active"}
                            onClick={() => {
                              readNotifications(item);
                            }}
                          >
                            <div className="wrap">
                              <strong>{item.event}</strong>
                              <span>
                                {moment(item.createdAt).format(
                                  "DD MMMM YYYY h:mma"
                                )}
                              </span>
                            </div>
                            <p>{item.module}</p>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p>No notification for You</p>
                  )}

                  {notificationList?.list?.length > 4 ? (
                    <div className="view-all" onClick={viewAllNotifications}>
                      View All
                    </div>
                  ) : null}
                </div>
                {/* Add active class after notification menu onlick on notification icon */}
              </div>
              <UserDropdown profile={studentProfile} />
            </div>
          </div>
        </nav>
      ) : (
        ""
      )}
      <div className="d-lg-none course-dropdown-xs">
        <ButtonDropdown
          list={studentProfile?.selectedCourses}
          onCourseSelect={onCourseSelect}
        />
      </div>
    </>
  );
};

export default AppBarUser;
