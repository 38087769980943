import React from "react";
//import { AppBarUser } from "@lipitech/edu-student-web";
import AppBarUser from "../common/AppBarUser";
import LipiEduAPI from "@lipitech/edu-client-sdk";
import { useNavigate } from "react-router-dom";
interface ITopAppBarUser {
  onCourseSelect: any;
  toggleDrawer: any;
}

const manageRedirectionFromNotifcation = (notification: any, navigate: any) => {
  console.log("view Notification Details and redirect to module", notification);
  switch (notification.module) {
    case "WALL":
      navigate(`/course/wall#${notification.id}`);
      break;
    case "DOUBTS":
      navigate(`/course/doubt#${notification.id}`);
      break;
    case "TEST":
      navigate(`/course/tests/details/${notification.id}`);
      break;
    case "BATCH":
      navigate(`/course/batch/${notification.id}`);
      break;
    case "INTERVIEW":
      navigate(`/course/interviews/${notification.id}`);
      break;
    default:
      break;
  }
};

export const TopAppBarUser = ({
  onCourseSelect,
  toggleDrawer,
}: ITopAppBarUser) => {
  const navigate = useNavigate();
  return (
    <AppBarUser
      toggleDrawer={toggleDrawer}
      getCurrentUser={LipiEduAPI.user.me}
      onCourseSelect={onCourseSelect}
      imageBaseUrl={LipiEduAPI.asset.getAssetUrl()}
      notifications={LipiEduAPI.notifications.list}
      markReadNotifications={LipiEduAPI.notifications.markRead}
      viewNotificationDetails={(notification: any) =>
        manageRedirectionFromNotifcation(notification, navigate)
      }
      viewAllNotifications={() => {
        navigate("/notifications");
      }}
    />
  );
};
