import React, { FC, useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { AiOutlineCaretDown } from "react-icons/ai";

const ButtonDropdown: FC<any> = ({ list = [], onCourseSelect }: any) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selected, setSelected] = useState<any>();

	useEffect(() => {
		if (list.length) {
			let selectedCourseIDFromLS = localStorage.getItem("SelectedCourseID");
			if (selectedCourseIDFromLS) {
				const seclectedCousre: any = list.find(
					({ course }: any) => course._id === selectedCourseIDFromLS
				);
				setSelected(seclectedCousre?.course || list[0]?.course);
				onCourseSelect &&
					onCourseSelect(seclectedCousre?.course || list[0]?.course || {});
			} else {
				setSelected(list[0]?.course);
				onCourseSelect && onCourseSelect(list[0]?.course);
			}
		}
	}, [list]);

	const handleSelect = (event: any, value: any) => {
		event.stopPropagation();
		setIsOpen(false);
		if (selected?.name !== value?.name) {
			setSelected(value);
			localStorage.setItem("SelectedCourseID", value._id);
			onCourseSelect && onCourseSelect(value, false, true);
		}
	};

	return (
		<Dropdown
			className="button-dropdown"
			show={isOpen}
			onToggle={() => setIsOpen((prev) => !prev)}
		>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				<span className="heading">{selected?.name || "Course"}</span>
				<div className="icon">
					<AiOutlineCaretDown size={12} />
				</div>
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{list.map(({ _id, course }: any) => {
					if (!(_id && course)) return null;
					return (
						<div
							key={_id}
							className="custom-item"
							onClick={(event: any) => handleSelect(event, course)}
						>
							<span className="custom-item-text">{course.name}</span>
							<Form.Group controlId={_id} className="custom-radio">
								<Form.Check
									type="radio"
									label=""
									value={course._id}
									checked={selected?._id === course._id}
									onChange={(event: any) => handleSelect(event, course)}
								/>
							</Form.Group>
						</div>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default ButtonDropdown;
