import React, {
  useEffect,
  useReducer,
  createContext,
} from "react";
import {
  useRoutes,
  BrowserRouter as Router,
} from "react-router-dom";
import "./app.scss";
import "@lipitech/student-theme";
import "react-toastify/dist/ReactToastify.css";
import routes from "./routes";
import { reducer } from "./Reducer";
import LipiEduAPI from "@lipitech/edu-client-sdk";

const Main = () => {
  useEffect(() => {
    const { styles: siteStyles, title } = LipiEduAPI.getConfig();
    const styles = siteStyles || {};
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link && styles?.favicon) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (styles?.favicon) {
      link.href = LipiEduAPI.asset.getAssetUrl(styles.favicon);
    }
    document.title = title || "";
  }, []);

  const routing = useRoutes(routes);

  return <>{routing}</>;
};


const initialState = {
  state: {
    page_loading: true,
    activeMenu: "live",
  },
  dispatch: () => { },
};

export const AppContext = createContext<{
  state: any;
  dispatch: any;
}>(initialState);

function App() {
  // const [state, setState] = useState<any>();
  const [state, dispatch] = useReducer(reducer, initialState.state);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Main />
    </AppContext.Provider>
  );
}
export default App;
