import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LipiEduAPI from "@lipitech/edu-client-sdk";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";

LipiEduAPI.setENV(process.env.REACT_APP_ENV!).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
        <ToastContainer />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
