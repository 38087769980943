import React, { useEffect } from "react";
import Sidebar from "../sidebar/sidebar";
import AuthAPI from "../../api/auth";
import { TopAppBarUser } from "../appbar/appBarUser";
import EDU_PRO from "../../svg/edu-pro.svg";
import MY_GOALS from "../../svg/goals.svg";
import ASSIGNMENT from "../../svg/assignment.svg";
import PROGRESS from "../../svg/progress.svg";
import DASHBOARD from "../../svg/dashboard.svg";
import EDU_PRO_ACTIVE from "../../svg/edu-pro_active.svg";
import MY_GOALS_ACTIVE from "../../svg/goals_active.svg";
import ASSIGNMENT_ACTIVE from "../../svg/assignment_active.svg";
import PROGRESS_ACTIVE from "../../svg/progress_active.svg";
import DASHBOARD_ACTIVE from "../../svg/dashboard_active.svg";
import LipiEduAPI from "@lipitech/edu-client-sdk";
import TEST_ICON from "../../svg/test.svg";
import TEST_ICON_ACTIVE from "../../svg/test_active.svg";
import { useLocation, useNavigate } from "react-router-dom";

const contentWidth = "calc(100% - 160px)";
const validateResourceAccess = (resource: string, navigate: any) => {
  if (!resource) {
    return;
  }
  if (!AuthAPI.hasResourceGrant(resource)) {
    navigate({
      pathname: `/free-resource`,
    });
  }
};
const Layout = ({
  children,
  onCourseSelect,
  selectedCourse,
}: {
  children: React.ReactNode;
  onCourseSelect?: any;
  selectedCourse?: any;
}) => {
  const [drawer, setDrawer] = React.useState(false);
  const [courseResouceValue, setCourseResourceValue] = React.useState(true);
  const [categoryList, setCategoryList] = React.useState([
    {
      name: "",
      link: "",
    },
  ]);
  const [isLoading, setLoading] = React.useState(true);
  const [menuList, setMenuList] = React.useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (AuthAPI.isLoggedIn() && !AuthAPI.getCurrentUser()) {
      AuthAPI.setCurrentUser()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          const pathname = location.pathname
            ? "/login?url=" + location.pathname
            : "/login";
          navigate({
            pathname,
          });
          // navigate.push('/logout');
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    LipiEduAPI.course.list({}).then(({ status, data }: any) => {
      if (status === 200) {
        data?.data.list.map((value: any) => {
          if (value._id == selectedCourse?._id) {
            setCourseResourceValue(value.showResource);
          }
        });
      }
    });
  }, [selectedCourse]);

  useEffect(() => {
    if (selectedCourse) {
      LipiEduAPI.category
        .activeList({ course: selectedCourse?._id, all: true })
        .then(({ status, data }: any) => {
          if (status === 200) {
            const subMenu: any = [];
            data.data?.map((item: any) => {
              subMenu.push({
                icon: EDU_PRO,
                activeIcon: EDU_PRO_ACTIVE,
                name: item.name,
                link: `/resource/${item._id}`,
                setMenu: true,
              });
            });
            setCategoryList(subMenu);
          }
        });
    }
  }, [selectedCourse]);
  const currentUser = AuthAPI.getCurrentUser();
  
  const menu = [
    {
      name: "Normal Menu",
      link: "",
      items: [
        {
          name: "Dashboard",
          icon: DASHBOARD,
          activeIcon: DASHBOARD_ACTIVE,
          link: "/dashboard",
          resource: "COURSE",
        },
        {
          name: "My Courses",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/edupro",
          module: "Manage Courses",
          resource: "COURSE",
        },
        {
          name: "Mentors",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/mentors",
        },
        {
          name: "Resource",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "#",
          items: categoryList,
        },
        {
          name: "Test Series",
          icon: TEST_ICON,
          activeIcon: TEST_ICON_ACTIVE,
          link: "/course/tests",
          resource: "COURSE",
        },
        {
          name: "My Assignment",
          icon: ASSIGNMENT,
          activeIcon: ASSIGNMENT_ACTIVE,
          link: "/myAssignment",
          resource: "MANAGE_ASSIGNMENTS",
        },
        {
          name: "Free Resources",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/free-resource",
          module: "FreeResource",
          resource: "MANAGE_FREE_RESOURCE",
        },
        {
          name: "Progress",
          icon: PROGRESS,
          activeIcon: PROGRESS_ACTIVE,
          link: "/progress",
          resource: [
            "MANAGE_BATCH",
            "LIVE_SESSIONS",
            "INTERVIEW",
            "TESTS",
            "TEST_BUNDLES",
          ],
        },
        {
          name: "All Courses",
          icon: MY_GOALS,
          activeIcon: MY_GOALS_ACTIVE,
          link: "/mygoals",
          resource: "COURSE",
        },
        {
          name: "Support",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/support",
          resource: "SUPPORT",
        },
      ],
    },
  ];

  const noResouceMenu = [
    {
      name: "No Resource Menu",
      link: "",
      items: [
        {
          name: "Dashboard",
          icon: DASHBOARD,
          activeIcon: DASHBOARD_ACTIVE,
          link: "/dashboard",
          resource: "COURSE",
        },
        {
          name: "My Courses",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/edupro",
          module: "Manage Courses",
          resource: "COURSE",
        },
        {
          name: "Test Series",
          icon: TEST_ICON,
          activeIcon: TEST_ICON_ACTIVE,
          link: "/course/tests",
        },
        // {
        //   name: "Mentors",
        //   icon: EDU_PRO,
        //   activeIcon: EDU_PRO_ACTIVE,
        //   link: "/mentors",
        // },
        // {
        //   name: "Live Class",
        //   icon: MY_GOALS,
        //   activeIcon: MY_GOALS_ACTIVE,
        //   link: "/course/remote-class",
        //   resource: "LIVE_CLASS",
        // },
        {
          name: "My Assignment",
          icon: ASSIGNMENT,
          activeIcon: ASSIGNMENT_ACTIVE,
          link: "/myAssignment",
          resource: "MANAGE_ASSIGNMENTS",
        },
        {
          name: "Free Resources",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/free-resource",
        },
        {
          name: "Progress",
          icon: PROGRESS,
          activeIcon: PROGRESS_ACTIVE,
          link: "/progress",
          resource: ["TESTS", "TEST_BUNDLES"],
        },
        {
          name: "Support",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/support",
          resource: "SUPPORT",
        },
        {
          name: "Edu Courses",
          icon: MY_GOALS,
          activeIcon: MY_GOALS_ACTIVE,
          link: "/mygoals",
        },
      ],
    },
  ];

  const toggleDrawer = () => {
    setDrawer((prevState) => !prevState);
  };

  useEffect(() => {
    if (courseResouceValue) {
      console.log("courseResouceValue", courseResouceValue);
      let data: any = courseResouceValue ? menu : noResouceMenu;

      setMenuList(data);
    }
  }, [courseResouceValue]);

  if (!currentUser) {
    return null;
  }

  return (
    <main className="student-layout">
      <Sidebar
        toggleDrawer={drawer}
        onCourseSelect={onCourseSelect}
        menu={menuList}
      />
      <section className="student-layout_wrap">
        <TopAppBarUser
          toggleDrawer={toggleDrawer}
          onCourseSelect={onCourseSelect}
        />
        <section className="student-layout_wrapper">
          <div className="wrapper-content" style={{ maxWidth: contentWidth }}>
            {children}
          </div>
        </section>
      </section>
    </main>
  );
};

export default Layout;
