import LipiEduAPI from "@lipitech/edu-client-sdk";

interface ILoginParms {
  email: string;
  password: string;
}

export enum UserRole {
  USER = "user",
  EDUCATOR = "educator",
  ADMIN = "admin",
  SUPERADMIN = "superadmin",
  CONTRIBUTOR = "contributor",
}

const GrantResources = LipiEduAPI.auth.GrantResources;

const token = localStorage.getItem("token");

if (token) {
  LipiEduAPI.setToken(token);
}
let currentUser: any | any = null;
let evaluatedPermissions: any = {};

const isLoggedIn = () => {
  if (currentUser) {
    return true;
  }

  const status = localStorage.getItem("token") !== null;
  if (status) {
    AuthAPI.isAuthenticated = true;
  }
  return status;
};

const setCurrentUser = () => {
  return LipiEduAPI.user.me().then(({ data }: any) => {
    // @ts-ignore
    currentUser = data?.data;

    const permissions = data?.data?.permissions || [];
    evaluatedPermissions = permissions.reduce((acc: any, it: any) => {
      // @ts-ignore
      const perm = GrantResources[it];
      if (perm) {
        acc[perm] = perm;
      }
      return acc;
    }, {});
    // dispatch({
    //   type: ActionType.GET_USER_SUCCESS,
    //   payload: currentUser,
    // });
    // if (currentUser?.center?.logo) {
    //   localStorage.setItem("logo", currentUser?.center?.logo);
    // }
    // if (currentUser?.center?.name) {
    //   localStorage.setItem("centerName", currentUser?.center?.name);
    // }
    // if (currentUser) {
    //   localStorage.setItem("user", JSON.stringify(currentUser));
    // }
    return currentUser;
  });
};

const getUserGrants = () => {
  return evaluatedPermissions;
};

const hasResourceGrant = (resource: string) => {
  if (currentUser && currentUser.role === UserRole.SUPERADMIN) {
    return true;
  }

  const grant = evaluatedPermissions[resource];
  return Boolean(grant);
};

const getCurrentUser = () => {
  if (currentUser) {
    return currentUser;
  }else{
    return null
  }
  // else if(localStorage.getItem('user')){
  //   return JSON.parse(localStorage.getItem('user') as string) || null
  // }
};

class HttpError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super(message);
    this.name = "HttpError";
    this.status = status;
  }
}

const login = ({ email, password }: ILoginParms) => {
  return LipiEduAPI.auth.login({ email, password }).then(
    (response) => {
      const token = response.data.token;
      localStorage.setItem("token", token);
      AuthAPI.isAuthenticated = true;
      return setCurrentUser();
    },
    ({ response }) => {
      console.log(response);
      if (response.status === 401) {
        throw new HttpError(
          response.status,
          (response.data && response.data.message) ||
            "Invalid Username or Password."
        );
      } else {
        throw new HttpError(
          response.status,
          (response.data && response.data.message) || "Internal Server Error."
        );
      }
    }
  );
};

const logout = () => {
  localStorage.clear();
  currentUser = null;
};

const getRedirectUrl = (user: any | null) => {
  let url = "/login";
  if (user) {
    switch (user.role) {
      case UserRole.USER:
        url = "/free-resource";
        break;
      case UserRole.ADMIN:
      case UserRole.SUPERADMIN:
        url = "/dashboard";
        break;
      case UserRole.EDUCATOR:
        url = "/dashboard";
        break;
      case UserRole.CONTRIBUTOR:
        url = "/dashboard";
        break;
      default:
        url = "/login";
    }
  }

  return url;
};

const getCacheUserMe = () => {
  console.log("user.me api called, data returned from cache");
  return new Promise((resolve, reject) => {
    const user = getCurrentUser();
    resolve({ data: user });
  });
};

const AuthAPI = {
  login,
  logout,
  isLoggedIn,
  setCurrentUser,
  isAuthenticated: false,
  getCurrentUser,
  getRedirectUrl,
  getUserGrants,
  hasResourceGrant,
  getCacheUserMe,
};

export default AuthAPI;
