import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import LipiEduAPI from '@lipitech/edu-client-sdk';
import user from '../../images/user.png';

const UserDropdown: FC<any> = ({ profile }: any) => {
  const profilePicture = profile?.displayPicture
    ? LipiEduAPI.asset.getAssetUrl(profile?.displayPicture)
    : user;
  return (
    <div>
      <Dropdown className="user-dropdown">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <div className="user-image">
            <img src={profilePicture} alt="user" />
          </div>
          <span className="user-name">{profile?.name}</span>
          <i className="icon-down-arrow"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/my-profile">Profile</Dropdown.Item>
          <Dropdown.Item className={"disabled"} as={Link} to="/setting">Setting</Dropdown.Item>
          {/* <Dropdown.Item as={Link} to="/support">Support</Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/logout">Sign Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default UserDropdown;
